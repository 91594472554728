import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {Col, Row} from "reactstrap";

import {ShopInfoProps, withShopInfo} from "../contexts/ShopInfoContext";
import LoginForm from 'tunnel-commande/src/components/tunnel/LoginForm';
import * as MonCompteAction from 'tunnel-commande/src/components/mon-compte/MonCompteAction';
import userStore from 'tunnel-commande/src/components/mon-compte/UserStore';
import {Helmet} from "react-helmet";
import {generateBaseUrl} from "../utils";

type LoginProps = WithTranslation & ShopInfoProps;

class Login extends React.Component<LoginProps, any> {

  private readonly initialValues: { password: string; email: string };

  constructor(props: LoginProps) {
    super(props);

    this.handleOnLoginFormValidate = this.handleOnLoginFormValidate.bind(this);
    this.onUserChanged = this.onUserChanged.bind(this);
    this.login = this.login.bind(this);

    this.initialValues = {email: '', password: ''};

    this.state = {
      disabled: true,
      values: {},
      error: null,
      appIsMounted: false
    };
  }

  static getInitialProps() {
    return {};
  }

  componentWillMount(): void {
    userStore.addListener('change', this.onUserChanged);
  }

  componentDidMount() {
    this.setState({
      appIsMounted: true
    });
  }

  componentWillUnmount(): void {
    userStore.removeListener('change', this.onUserChanged);
  }

  private onUserChanged() {
    if(userStore.getUser()) {
      window.location.href = `/${this.props.i18n.language}/mon-compte`;
    }
  }

  private login() {
    if(!this.state.disabled) {
      this.setState({error: null});

      MonCompteAction.login({...this.state.values}).catch(err => {
        console.log('erreur login', err);
        this.setState({
          error: err.response?.data?.message
        });
      });
    }
  }

  private handleOnLoginFormValidate(validate: any, values: any) {
    this.setState({
      disabled: !validate,
      values: values
    });
  }

  render() {
    const baseUrl = generateBaseUrl(this.props.shopInfo.env);
    const canonical = `${baseUrl}/${this.props.i18n.language}/login`;

    return (
      <>
        <Helmet>
          <title>{this.props.t('login_title')}</title>
          <meta name="description" content={this.props.t('login_meta_description')} />
          <meta property="og:description" content={this.props.t('login_meta_description')} />
          <meta property="og:title" content={this.props.t('login_title')} />
          <meta property="og:site_name" content="DuckTransfer" />
          <meta property="og:locale" content={this.props.i18n.language + '_' + this.props.i18n.language.toUpperCase()} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonical} />
          <script data-cookieconsent="ignore" src="/lib/js/translation/translation.service.js" />
          <meta property="og:image" content={`${baseUrl}/images/logo-ducktransfer.jpg`} />
          <link rel="canonical" href={canonical} />
        </Helmet>
        <div className={'container-fluid m-0 p-0 p-0 h-100  position-relative'}>
          <div className={'bg-lightgrey container-fluid py-md-5 px-0 px-md-5'}>
            <div className={'container-fluid container-fluid-dt'}>
              <Row>
                <Col  sm={{ size: 4, offset: 4 }}  md={{ size: 6, offset: 3}}  lg={{ size: 6, offset: 3 }}  xl={{ size: 4, offset: 4 }}>
                  <div className=" mt-5 blocConnexion">
                  <div className="border">
                    <h1 className="bg-black white m-0 p-2 font20">{this.props.t('login_h1')}</h1>
                    <div className="p-4 bg-white text-center">
                      {this.state.error &&
                        <div className="alert alert-danger" role="alert">
                          {this.state.error}
                        </div>
                      }

                      <LoginForm initialValues={this.initialValues} onReturnPress={this.login} resetUrl={`/${this.props.i18n.language}/customer-reset`} onValidate={this.handleOnLoginFormValidate} />
                            <button onClick={() => this.login()} disabled={this.state.disabled} className="reset-button buttonPrimary mt-4">
                              {this.props.t('connection')}
                          </button>

                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="mb-0 bold"><u>{this.props.t('for_new_customers')}</u></p>
                    <p>{this.props.t('for_new_customers_text')}</p>
                  </div>
                </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation('login')(withShopInfo(Login));
